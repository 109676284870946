import { cx } from '@emotion/css';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  languages,
  menuDining,
  menuDiscover,
  menus,
  menusRooms,
  ROUTES,
} from '../../../utils/constant';
import { ReactComponent as MocoLogo } from './../../../assets/logo/logo.svg';
import { MocoContext } from '../../../context/MocoContext';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import i18n from '../../../utils/i18n';
import { SOCIAL_URL } from '../../../utils/social-url';

interface IForm {
  onValidated: any;
  message?: any;
  status?: any;
}
const CustomForm: React.FC<IForm> = ({ message, onValidated }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState('');
  const submit = () =>
    email &&
    email.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email,
    });

  return (
    <>
      <div className="mail-chimp flex flex-col md:w-3/5 md:flex-row">
        <input
          className="mr-3 h-10 w-full px-4 md:w-4/5"
          type="text"
          placeholder={t('your_email')}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="mb-1 pt-2">
          <input
            type="checkbox"
            className="mr-2 border border-main"
            onChange={(e) => setConsent(e.target.value)}
          />
          <span>{t('cookie_consent')}</span>
        </div>
        <button
          disabled={email && consent ? false : true}
          className="w-40 bg-gold py-1 text-center text-2xl text-white md:w-auto"
          onClick={submit}
        >
          {t('subscribe')}
        </button>
      </div>
      <div className="pt-2">{message}</div>
    </>
  );
};

const MobileMenu: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const myRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [isOpen, setOpen] = useState(false);
  const [isSelectLng, setSelectLng] = useState(false);
  const [isSelectRoom, setSelectRoom] = useState(false);
  const [isSelectDiscover, setSelectDiscover] = useState(false);
  const [isSelectDining, setSelectDining] = useState(false);
  const { dispatch } = useContext(MocoContext);
  const url =
    'https://hotelmoco.us5.list-manage.com/subscribe/post?u=318941cb6512ce6a2a7a19d75&amp;id=68f714cf3c';

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const onClickMenu = () => {
    setOpen(!isOpen);
  };

  const onSelectLng = () => {
    setSelectLng(!isSelectLng);
  };

  const onSelectMenu = (key: string, path: string) => {
    const menus = ['menu_discover', 'menu_room', 'menu_dining'];
    if (!menus.includes(key)) {
      window.location.href = path;
    } else if (key === menus[0]) {
      setSelectDiscover(!isSelectDiscover);
    } else if (key === menus[1]) {
      setSelectRoom(!isSelectRoom);
    } else if (key === menus[2]) {
      setSelectDining(!isSelectDining);
    }
  };

  const onNavigateDining = (key: string, path: string) => {
    dispatch({
      type: 'UPDATE_DINING_TYPE',
      payload: {
        diningType: key,
      },
    });
    setSelectDining(!isSelectDining);
    setOpen(false);
    window.location.href = path;
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (!myRef.current || myRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
      setSelectRoom(false);
      setSelectRoom(false);
      setSelectDining(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [myRef]);

  useEffect(() => {
    document.body.style.overflowY = isOpen ? 'hidden' : '';
  }, [isOpen]);

  return (
    <div
      className={`${
        i18n.language === 'th' ? 'text-xl font-bold' : ' text-base tracking-modny'
      } relative z-10 w-full `}
    >
      {isOpen && (
        <>
          <div className="absolute z-20 h-screen w-screen bg-black bg-opacity-60"></div>
          <div id="sideMenu" ref={myRef}>
            <ul className="fixed z-30 flex h-full w-9/12 animate-slide flex-col justify-start overflow-y-scroll bg-whiteGreen bg-opacity-95 pb-16 capitalize text-green">
              <li className="w-full border-b-2 border-main py-4 pl-6">
                <img src="/images/logo-moco-green.svg" alt="" />
              </li>
              {Object.keys(menus).map((key) => (
                <div
                  className={`border-b-2 border-main bg-opacity-95 ${
                    ((isSelectRoom && menus[key] === 'menu_room') ||
                      (isSelectDiscover && menus[key] === 'menu_discover') ||
                      (isSelectDining && menus[key] === 'menu_dining')) &&
                    'bg-greenLight'
                  }`}
                  key={key}
                >
                  <li className="w-full py-2 px-6">
                    <span
                      className="flex justify-between"
                      onClick={() => onSelectMenu(menus[key], key)}
                    >
                      {t(menus[key])}
                      {(menus[key] === 'menu_room' ||
                        menus[key] === 'menu_discover' ||
                        menus[key] === 'menu_dining') && (
                        <img
                          className={
                            (isSelectRoom && menus[key] === 'menu_room') ||
                            (isSelectDiscover && menus[key] === 'menu_discover') ||
                            (isSelectDining && menus[key] === 'menu_dining')
                              ? 'ml-1 w-3 rotate-180 transform'
                              : 'ml-1 w-3'
                          }
                          src="/images/ic-expand-green.svg"
                          alt=""
                        />
                      )}
                    </span>
                  </li>
                  {isSelectRoom && menus[key] === 'menu_room' && (
                    <>
                      {Object.keys(menusRooms).map((key) => (
                        <li
                          key={key}
                          className="w-full bg-greenLight bg-opacity-95 py-2 pl-8 capitalize"
                          onClick={() => {
                            history.push(key);
                            setOpen(!isOpen);
                            setSelectRoom(!isSelectRoom);
                          }}
                        >
                          {t(menusRooms[key])}
                        </li>
                      ))}
                    </>
                  )}
                  {isSelectDiscover && menus[key] === 'menu_discover' && (
                    <>
                      {Object.keys(menuDiscover).map((key) => (
                        <li
                          key={key}
                          className="w-full bg-greenLight bg-opacity-95 py-2 pl-8 capitalize"
                          onClick={() => {
                            history.push(key);
                            setOpen(!isOpen);
                            setSelectDiscover(!isSelectDiscover);
                          }}
                        >
                          {t(menuDiscover[key])}
                        </li>
                      ))}
                    </>
                  )}
                  {isSelectDining && menus[key] === 'menu_dining' && (
                    <>
                      {Object.keys(menuDining).map((key) => (
                        <li
                          key={key}
                          className="w-full bg-greenLight bg-opacity-95 py-2 pl-8 capitalize"
                          onClick={() => onNavigateDining(menuDining[key], key)}
                        >
                          {t(menuDining[key])}
                        </li>
                      ))}
                    </>
                  )}
                </div>
              ))}

              <li
                className={`flex w-full justify-between py-2 px-6 ${
                  isSelectLng && 'bg-greenLight'
                }`}
                onClick={onSelectLng}
              >
                <div className="flex items-center font-modny text-base">
                  {t('language')}
                  <img
                    className="ml-4 h-3 w-6"
                    src={`/images/ic-${i18n.language}.png`}
                    alt="icon language"
                  />
                </div>
                <img
                  className={isSelectLng ? 'ml-1 w-3 rotate-180 transform' : 'ml-1 w-3'}
                  src="/images/ic-expand-green.svg"
                  alt=""
                />
              </li>
              {isSelectLng && (
                <>
                  {Object.keys(languages).map((key) => (
                    <li
                      key={key}
                      className="flex w-full items-center bg-greenLight py-2 pl-8 font-modny text-base capitalize"
                      onClick={() => {
                        changeLanguage(key);
                        onSelectLng();
                      }}
                    >
                      <img
                        className="mr-2 h-3 w-6"
                        src={`/images/ic-${key}.png`}
                        alt="icon language"
                      />
                      {languages[key]}
                    </li>
                  ))}
                </>
              )}
              <li className="w-full border-b-2 border-t-2 border-main py-5 px-6 normal-case">
                <div className="pb-1">
                  {t('subscribe_desc')} <span className="font-modny text-base"> Hotel MOCO </span>
                </div>
                <MailchimpSubscribe
                  url={url}
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      message={message}
                      onValidated={(formData: any) => subscribe(formData)}
                    />
                  )}
                />
              </li>
              <li className="flex w-full py-5 pl-6 pr-3">
                <img
                  className="mx-4"
                  src="/images/ic-facebook.svg"
                  alt=""
                  onClick={() => window.open(SOCIAL_URL.MOCO.facebook)}
                />
                <img
                  src="/images/ic-instagram.svg"
                  alt=""
                  onClick={() => window.open(SOCIAL_URL.MOCO.instagram)}
                />
              </li>
            </ul>
          </div>
        </>
      )}
      <div className="flex w-full flex-col">
        <div
          className={cx('flex h-12 w-full items-center justify-between bg-primary px-4 text-white')}
        >
          <div
            onClick={onClickMenu}
            className="flex h-full cursor-pointer border-r border-opacity-50 align-middle"
          >
            <img className="mr-4 w-6" src="/images/ic-menu.svg" alt="" />
          </div>
          <MocoLogo className="cursor-pointer p-3" onClick={() => history.push(ROUTES.HOME)} />
          <img
            className="w-6"
            src="/images/ic-phone.svg"
            onClick={() => window.open('tel:042219456')}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
