import { cx } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  languages,
  menuDining,
  menuDiscover,
  menus,
  menusRooms,
  ROUTES,
} from '../../../utils/constant';
import { ReactComponent as MocoLogo } from './../../../assets/logo/logo.svg';
import i18n from '../../../utils/i18n';
import { useState, useRef, useEffect, useContext } from 'react';
import { MocoContext } from '../../../context/MocoContext';
import i18next from 'i18next';
const DesktopMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isSelectLng, setSelectLng] = useState(false);
  const [isSelectRoom, setSelectRoom] = useState(false);
  const [isSelectDiscover, setSelectDiscover] = useState(false);
  const [isSelectDining, setSelectDining] = useState(false);
  const myRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const menuRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { dispatch } = useContext(MocoContext);
  const [lang, setLang] = useState<string>(i18n.language);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const onSelectMenu = (key: string, path: string) => {
    const menus = ['menu_discover', 'menu_room', 'menu_dining'];
    if (!menus.includes(key)) {
      window.location.href = path;
    } else if (key === menus[0]) {
      setSelectDiscover(!isSelectDiscover);
    } else if (key === menus[1]) {
      setSelectRoom(!isSelectRoom);
    } else if (key === menus[2]) {
      setSelectDining(!isSelectDining);
    }
  };

  const onNavigateDining = (key: string, path: string) => {
    dispatch({
      type: 'UPDATE_DINING_TYPE',
      payload: {
        diningType: key,
      },
    });
    setSelectDining(!isSelectDining);
    window.location.href = path;
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (!myRef.current || myRef.current.contains(event.target)) {
        return;
      }
      // setSelectLng(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [myRef]);

  useEffect(() => {
    const listener = (event: any) => {
      if (!menuRef.current || menuRef.current.contains(event.target)) {
        return;
      }
      setSelectRoom(false);
      setSelectDiscover(false);
      setSelectDining(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [menuRef]);

  useEffect(() => {
    i18next.on('languageChanged', () => {
      setLang(i18n.language);
    });
  }, []);

  return (
    <div className={`flex w-full flex-col ${lang === 'en' ? 'text-base' : 'text-xl'}`}>
      <div
        className={cx(
          'flex h-16 w-full items-center justify-between justify-items-center bg-primary px-10 font-bold text-white',
        )}
      >
        <div className="relative w-36 font-modny text-base">
          <div className="flex w-36 cursor-pointer" onClick={() => setSelectLng(true)}>
            <div className="flex items-center pr-2">
              <span className="pr-4">{t('language')}</span>
              <img className="h-3 w-6" src={`/images/ic-${lang}.png`} alt="icon language" />
            </div>
            <img className="w-3" src="/images/ic-arrow.svg" alt="" />
          </div>
          {isSelectLng && (
            <div
              className="absolute mt-5 w-56 border-t-2 border-greenBrowLight bg-primary"
              ref={myRef}
            >
              {Object.keys(languages).map((key) => (
                <div
                  key={key}
                  className="flex w-full cursor-pointer items-center py-2 pl-6 capitalize"
                  onClick={() => {
                    changeLanguage(key);
                    setSelectLng(!isSelectLng);
                  }}
                >
                  <img className="h-3 w-6" src={`/images/ic-${key}.png`} alt="icon language" />{' '}
                  <span className="pl-2"> {languages[key]}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <MocoLogo className="w-36 cursor-pointer p-2" onClick={() => history.push(ROUTES.HOME)} />
        <div
          className="w-36 cursor-pointer text-right"
          onClick={() =>
            window.open('https://book-directonline.com/properties/montatiphoteldirect', '_blank')
          }
        >
          {t('book_now')}
        </div>
      </div>
      <ul className="flex h-14 items-center justify-center bg-white font-bold capitalize">
        {Object.keys(menus).map((key) => (
          <li className="mr-5 cursor-pointer text-center" key={key}>
            <span className="flex hover:text-gold" onClick={() => onSelectMenu(menus[key], key)}>
              {t(menus[key])}
              {(menus[key] === 'menu_room' ||
                menus[key] === 'menu_discover' ||
                menus[key] === 'menu_dining') && (
                <img
                  className={
                    (isSelectRoom && menus[key] === 'menu_room') ||
                    (isSelectDiscover && menus[key] === 'menu_discover') ||
                    (isSelectDining && menus[key] === 'menu_dining')
                      ? 'ml-1 w-3 rotate-180 transform'
                      : 'ml-1 w-3'
                  }
                  src="/images/ic-expand-green.svg"
                  alt=""
                />
              )}
            </span>
            <div>
              {/* menu_room */}
              {isSelectRoom && menus[key] === 'menu_room' && (
                <>
                  <div className="h-1 w-full border-t-2 border-green"></div>
                  <div className="absolute mt-2 bg-greenLight text-green" ref={menuRef}>
                    {Object.keys(menusRooms).map((key) => (
                      <div
                        key={key}
                        className="flex w-full cursor-pointer items-center py-2 px-8 capitalize"
                        onClick={() => {
                          history.push(key);
                          setSelectRoom(!isSelectRoom);
                        }}
                      >
                        {t(menusRooms[key])}
                      </div>
                    ))}
                  </div>
                </>
              )}
              {/* menu_discover */}
              {isSelectDiscover && menus[key] === 'menu_discover' && (
                <>
                  <div className="h-1 w-full border-t-2 border-green"></div>
                  <div className="absolute mt-2 bg-greenLight text-green" ref={menuRef}>
                    {Object.keys(menuDiscover).map((key) => (
                      <div
                        key={key}
                        className="flex w-full cursor-pointer items-center py-2 px-8 capitalize"
                        onClick={() => {
                          history.push(key);
                          setSelectDiscover(!isSelectDiscover);
                        }}
                      >
                        {t(menuDiscover[key])}
                      </div>
                    ))}
                  </div>
                </>
              )}
              {/* menu_dining */}
              {isSelectDining && menus[key] === 'menu_dining' && (
                <>
                  <div className="h-1 w-full border-t-2 border-green"></div>
                  <div className="absolute mt-2 bg-greenLight text-green" ref={menuRef}>
                    {Object.keys(menuDining).map((key) => (
                      <div
                        key={key}
                        className="flex w-full cursor-pointer items-center py-2 px-8 capitalize"
                        onClick={() => onNavigateDining(menuDining[key], key)}
                      >
                        {t(menuDining[key])}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DesktopMenu;
