interface TitleSectionProps {
  title: string;
}

const TitleSection: React.FC<TitleSectionProps> = ({ title }) => {
  return (
    <div className="relative my-4 w-full">
      <div className="child-center absolute bg-greenYellowLight px-4 text-center text-lg uppercase tracking-widest md:text-2xl">
        {title}
      </div>
      <div className="flex h-7 items-center">
        <div className="h-1 w-full border-t-2 border-greenBrowLight"></div>
      </div>
    </div>
  );
};

export default TitleSection;
