import { useTranslation } from 'react-i18next';
import i18n from '../utils/i18n';

const Complimentary = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-6 grid grid-cols-2 pr-0 md:grid-cols-3 md:pr-12">
      <div className="flex flex-col items-center py-2">
        <img className="h-10 md:h-16" src="/images/ic-breakfast.svg" alt="" />
        <div className={`${i18n.language === 'th' && 'md:text-lg'} p-2 text-center font-bold`}>
          {t('complimentary_breakfast')}
        </div>
      </div>
      <div className="flex flex-col items-center py-2">
        <img className="h-10 md:h-16" src="/images/ic-drink.svg" alt="" />
        <div className={`${i18n.language === 'th' && 'md:text-lg'} p-2 text-center font-bold`}>
          {t('welcome_drink')}
        </div>
      </div>
      <div className="flex flex-col items-center py-2">
        <img className="h-10 md:h-16" src="/images/ic-snack.svg" alt="" />
        <div className={`${i18n.language === 'th' && 'md:text-lg'} p-2 text-center font-bold`}>
          {t('snack_drink')}
        </div>
      </div>
      <div className="flex flex-col items-center justify-end py-2 md:py-6">
        <img className="h-10 md:h-14" src="/images/ic-wifi.svg" alt="" />
        <div className={`${i18n.language === 'th' && 'md:text-lg'} p-2 text-center font-bold`}>
          {t('wifi_access')}
        </div>
      </div>
      <div className="flex flex-col items-center py-2 md:py-6">
        <img className="h-10 md:h-16" src="/images/ic-coffee.svg" alt="" />
        <div className={`${i18n.language === 'th' && 'md:text-lg'} p-2 text-center font-bold`}>
          {t('coffee_machine')}
        </div>
      </div>
      <div className="flex flex-col items-center py-2 md:py-6">
        <img className="h-10 md:h-16" src="/images/ic-clock.svg" alt="" />
        <div className={`${i18n.language === 'th' && 'md:text-lg'} p-2 text-center font-bold`}>
          {t('alarm_clock')}
        </div>
      </div>
    </div>
  );
};

export default Complimentary;
