import { useTranslation } from 'react-i18next';
import TitleSection from '../../../TitleSection';

const Explore = () => {
  const { t } = useTranslation();
  return (
    <>
      <TitleSection title={t('menu_our_story')} />
      <div className="pt-5 pb-10">
        <div className="flex items-center justify-center">
          <img className="max-h-96 px-0 py-5 md:px-40" src="/images/our-story.jpg" alt="" />
        </div>
        <div className="grid p-0 py-5 md:grid-cols-5 md:px-10">
          <div className="pb-4 pr-0 text-center text-3xl font-bold text-green md:col-span-2 md:pb-0 md:pr-10 md:text-left">
            {t('explore_title')}
          </div>
          <div className="md:col-span-3">
            <div className="text-center md:text-left">{t('explore_desc')}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Explore;
