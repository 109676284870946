import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavbarMenu from '../../components/NavbarMenu';
import Subscribe from '../../components/Subscribe';
import { SOCIAL_URL } from '../../utils/social-url';
import emailjs from 'emailjs-com';
import { useToasts } from 'react-toast-notifications';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactPage = () => {
  const { t } = useTranslation();
  const form = useRef<any>(null);
  const { addToast } = useToasts();
  const [captcha, setCaptcha] = useState('');

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const sendEmail = (event: any) => {
    event.preventDefault();
    const formBody = form ? form.current : null;
    emailjs
      .sendForm('service_6vglg0d', 'template_8twkdoy', formBody, 'user_lWwqsc92hwFRt6HyuKd7A')
      .then(
        (result) => {
          console.log(result.text);
          addToast(t('success_contact'), {
            appearance: 'success',
            autoDismiss: true,
          });
          setTimeout(() => {
            if (form) form.current.reset();
          }, 500);
        },
        (error) => {
          console.log(error.text);
          addToast(t('error_contact'), {
            appearance: 'error',
            autoDismiss: true,
          });
        },
      );
  };

  const onChange = (value: any) => {
    console.log('Captcha value:', value);
    setCaptcha(value);
  };

  return (
    <>
      <NavbarMenu />
      <div className="w-full bg-greenYellowLight px-10 py-10 md:px-20">
        <div className="pb-10 text-center text-3xl text-green">{t('menu_contact_us')}</div>
        <div className="flex flex-col md:grid md:grid-cols-2">
          <div className="md:col-span-1">
            <div className="pb-4 font-modny text-base font-semibold text-green md:px-20">
              {t('contact_hotel_name')}
            </div>
            <div className="tracking-wide md:px-20">
              <div>{t('contact_address')}</div>
              <div>{t('contact_tel')}: 042 219 456</div>
              <div>
                {t('contact_facebook')}:{' '}
                <span
                  className="cursor-pointer"
                  onClick={() => window.open(SOCIAL_URL.MOCO.facebook)}
                >
                  HotelmocoUdonthani
                </span>{' '}
              </div>
              <div>{t('contact_line')}: @hotelmoco</div>
              <div>
                {t('contact_instagram')}:{' '}
                <span
                  className="cursor-pointer"
                  onClick={() => window.open(SOCIAL_URL.MOCO.instagram)}
                >
                  hotelmocoudonthani
                </span>
              </div>
            </div>
            <div className="w-full items-center py-10 md:px-10">
              <img
                className="cursor-pointer"
                src="/images/map.png"
                alt=""
                onClick={() => window.open('https://goo.gl/maps/RT7nJB9Sw7TwFMCS8')}
              />
            </div>
          </div>
          <form ref={form} onSubmit={sendEmail}>
            <div className="md:col-span-1 ">
              <div className="pb-4 text-2xl font-semibold text-green">{t('please_fill_info')}</div>
              <div className="text-xl">{t('firstname')}</div>
              <input
                className="mb-4 h-10 w-full border border-greenBrowLight bg-whiteGreen px-2"
                type="text"
                required
                name="firstname"
              />
              <div className="text-xl">{t('lastname')}</div>
              <input
                className="mb-4 h-10 w-full border border-greenBrowLight bg-whiteGreen px-2"
                type="text"
                required
                name="lastname"
              />
              <div className="text-xl">{t('mobile')}</div>
              <input
                className="mb-4 h-10 w-full border border-greenBrowLight bg-whiteGreen px-2"
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                name="mobile"
                required
              />
              <div className="text-xl">{t('email')}</div>
              <input
                className="mb-4 h-10 w-full border border-greenBrowLight bg-whiteGreen px-2"
                type="email"
                name="email_from"
                required
              />
              <div className="text-xl">{t('your_message')}</div>
              <textarea
                className="mb-4 h-40 w-full border border-greenBrowLight bg-whiteGreen px-2"
                required
                name="message"
              />
              <ReCAPTCHA sitekey="6Lfof6MdAAAAAPmOC0gQ6vPYG8mOPhggAuUR0-Xo" onChange={onChange} />
              <button
                type="submit"
                disabled={!captcha}
                className="mt-4 bg-gold px-20 py-2 text-xl uppercase text-white"
              >
                {t('send')}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Subscribe />
    </>
  );
};

export default ContactPage;
