export const SOCIAL_URL = {
  UPTOWN: {
    facebook: 'https://www.facebook.com/UDTOWN/',
    instagram: 'https://www.instagram.com/udtown/',
    web: '',
  },
  MONTATIP_HALL: {
    facebook: 'https://www.facebook.com/montatiphall/',
    instagram: '',
    web: 'https://www.montatiphall.com/',
  },
  PLEARN_SPA: {
    facebook: 'https://www.facebook.com/PlearnSpa',
    instagram: '',
    web: '',
  },
  PAR: {
    facebook: 'https://www.facebook.com/parrestaurant',
    instagram: 'https://www.instagram.com/par.restaurant/',
    web: '',
  },
  BAR: {
    facebook: '',
    instagram: '',
    web: '',
  },
  BRANCH_MUNCH: {
    facebook: 'https://www.facebook.com/BrunchnMunch.udtown/',
    instagram: 'https://www.instagram.com/brunchnmunchh/',
    web: '',
  },
  MOCO: {
    facebook: 'https://www.facebook.com/HotelMOCOUdonthani',
    instagram: 'https://www.instagram.com/hotelmocoudonthani/',
    web: '',
  },
};
