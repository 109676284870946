import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Slider from 'react-slick';
import { ROUTES } from '../utils/constant';
import { NextArrow, PrevArrow } from '../utils/slider-arrow';

const RoomsGallery = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const sliderImgRef = useRef<Slider>(null);
  const sliderContentRef = useRef<Slider>(null);

  const settings = {
    className: 'center',
    infinite: false,
    slidesToShow: 1,
    initialSlide: 1,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (_current: any, next: any) => {
      sliderContentRef.current && sliderContentRef.current.slickGoTo(next);
    },
  };

  const settingsContent = {
    className: 'center',
    infinite: false,
    slidesToShow: 1,
    initialSlide: 1,
    speed: 500,
    swipe: false,
  };

  return (
    <div className="py-4">
      <div className="relative my-4 w-full px-5 md:px-20 xl:px-56 2xl:px-80">
        <div className="child-center absolute bg-white px-4 text-lg uppercase tracking-widest md:text-2xl">
          {t('section_rejuvenate')}
        </div>
        <div className="flex h-7 items-center">
          <div className="h-1 w-full border-t-2 border-greenBrowLight"></div>
        </div>
      </div>
      <div className="grid px-5 pt-6 md:grid-cols-3 md:px-20 xl:px-56 2xl:px-80">
        <div className="text-center text-3xl font-bold text-green md:col-span-1 md:text-left">
          {t('room_title')}
        </div>
        <div className="px-10 pb-4 text-center md:col-span-2 md:text-left">{t('room_desc')}</div>
      </div>
      <div className={`${window.screen.width < 768 ? 'single-slide' : ''}`}>
        <Slider
          ref={sliderImgRef}
          {...settings}
          centerMode={window.screen.width > 767 ? true : false}
          centerPadding={window.screen.width > 767 ? '20%' : '0'}
        >
          <div>
            <img className="w-full" src="/images/rooms/superior.jpg" alt="deluxe-room" />
          </div>
          <div>
            <img className="w-full" src="/images/rooms/deluxe.jpg" alt="superior-room" />
          </div>
          <div>
            <img className="w-full" src="/images/rooms/junior-suite.jpg" alt="junior-suite-room" />
          </div>
          <div>
            <img className="w-full" src="/images/rooms/moco-suite.jpg" alt="moco-suite-room" />
          </div>
        </Slider>
        <Slider
          ref={sliderContentRef}
          {...settingsContent}
          centerMode={window.screen.width > 767 ? true : false}
          centerPadding={window.screen.width > 767 ? '20%' : '0'}
        >
          <div>
            <div className="grid md:grid-cols-4">
              <div className="text-center text-3xl text-green md:col-span-1 md:text-left">
                {t('menu_room_superior')}
              </div>
              <div className="md:col-span-3 md:pl-4">
                <div className="pb-4 text-center md:text-left">{t('room_desc_superior')}</div>
                <div className="text-center md:text-left">
                  <button
                    onClick={() => history.push(ROUTES.ROOMS_SUPERIOR)}
                    className="bg-gold px-9 py-2 capitalize text-white"
                  >
                    {t('check_availability')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="grid md:grid-cols-4">
              <div className=" text-center text-3xl text-green md:text-left">
                {t('menu_room_deluxe')}
              </div>
              <div className="md:col-span-3 md:pl-4">
                <div className="pb-4 text-center md:text-left">{t('room_desc_deluxe')}</div>
                <div className="text-center md:text-left">
                  <button
                    onClick={() => history.push(ROUTES.ROOMS_DELUXE)}
                    className="bg-gold px-9 py-2 capitalize text-white"
                  >
                    {t('check_availability')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="grid md:grid-cols-4">
              <div className=" text-center text-3xl text-green md:text-left">
                {t('menu_room_junior_suite')}
              </div>
              <div className="md:col-span-3 md:pl-4">
                <div className="pb-4 text-center md:text-left">{t('room_desc_junior_suite')}</div>
                <div className="text-center md:text-left">
                  <button
                    onClick={() => history.push(ROUTES.ROOMS_JUNIOR_SUITE)}
                    className="bg-gold px-9 py-2 capitalize text-white"
                  >
                    {t('check_availability')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="grid md:grid-cols-4">
              <div className=" text-center text-3xl text-green md:text-left">
                {t('menu_room_moco_suite')}
              </div>
              <div className="md:col-span-3 md:pl-4">
                <div className="pb-4 text-center md:text-left">{t('room_desc_moco')}</div>
                <div className="text-center md:text-left">
                  <button
                    onClick={() => history.push(ROUTES.ROOMS_MOCO_SUITE)}
                    className="bg-gold px-9 py-2 capitalize text-white"
                  >
                    {t('check_availability')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default RoomsGallery;
