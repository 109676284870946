import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../utils/constant';
import i18n from '../../../utils/i18n';

const Home: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="w-full bg-greenYellowLight px-10 pt-10 md:px-20 xl:px-56 2xl:px-80">
      <div className="grid items-end md:grid-cols-5">
        <div className="md:col-span-3">
          <img src="/images/moco-hotel.jpg" alt="" />
        </div>
        <div className="flex justify-center md:hidden">
          <div
            className={`${
              i18n.language === 'th' ? 'text-3xl font-bold' : 'text-3xl'
            } col-span-2 py-10  text-center text-green`}
          >
            {t('home_title')}
          </div>
        </div>
        <div className="md:col-span-2 md:pl-10">
          <img src="/images/moco-front.png" alt="" />
        </div>
      </div>
      <div className="grid grid-flow-col pt-10 align-top md:grid-cols-5">
        <div
          className={`${
            i18n.language === 'th' ? 'text-3xl font-bold' : 'text-3xl'
          } col-span-2 hidden px-10 text-center text-3xl  text-green md:flex`}
        >
          {t('home_title')}
        </div>
        <div className="col-span-3 text-center md:text-left">
          {t('home_title_desc') && (
            <div className={`${i18n.language === 'th' ? 'text-2xl' : 'text-lg'} font-bold`}>
              {t('home_title_desc')}
            </div>
          )}
          <div>{t('home_desc')}</div>
          <div className="flex justify-center md:justify-start">
            <button
              onClick={() => history.push(ROUTES.FACILITY)}
              className={`${
                i18n.language === 'th' ? 'text-2xl' : 'text-lg'
              } mt-4 bg-gold px-9 py-2 text-center text-white`}
            >
              {t('more_detail')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
