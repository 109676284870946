import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../../utils/constant';
import i18n from '../../../../utils/i18n';
import TitleSection from '../../../TitleSection';

const Discover = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="h-100 block">
      <TitleSection title={t('section_discover')} />
      <div className="py-10 md:grid md:grid-cols-2 md:px-20">
        <div className="flex flex-col-reverse items-center py-5 md:grid md:px-10">
          <div className="pt-8 md:pt-0">
            <div className="text-center text-xl md:text-left">
              {' '}
              <span className={`${i18n.language === 'th' ? 'text-2xl font-bold' : ''}`}>
                {t('culture')}
              </span>
            </div>
            <div className="text-center uppercase tracking-wide md:text-left">
              <span className={`${i18n.language === 'th' ? 'font-bold' : ''}`}>
                {t('culture_second')}
              </span>
            </div>
            <div className="text-center md:text-left">{t('culture_desc')}</div>
            <div className="text-center md:text-left">
              <button
                onClick={() => history.push(ROUTES.DISCOVER_CULTURE)}
                className="mt-4 w-40 bg-gold px-9 py-2 text-center capitalize text-white"
              >
                {t('more_detail')}
              </button>
            </div>
          </div>
          <img className="pt-8" src="/images/discover/culture.jpg" alt="" />
        </div>
        <div className="flex flex-col items-center py-5 md:items-baseline md:px-10 ">
          <img className="pb-8" src="/images/discover/tradition.jpg" alt="" />
          <div className=" text-xl">
            <span className={`${i18n.language === 'th' ? 'text-2xl font-bold' : ''}`}>
              {t('tradition')}
            </span>
          </div>
          <div className="uppercase tracking-wide">
            <span className={`${i18n.language === 'th' ? 'font-bold' : ''}`}>
              {t('tradition_second')}
            </span>
          </div>
          <div className="items-center md:text-left">{t('tradition_desc')}</div>
          <button
            onClick={() => history.push(ROUTES.DISCOVER_CULTURE)}
            className="mt-4 w-40 bg-gold px-9 py-2 text-center capitalize text-white"
          >
            {t('more_detail')}
          </button>
        </div>
        <div className="flex flex-col-reverse items-center py-5 md:grid md:px-10">
          <div className="pt-4 md:pt-0">
            <div className="text-center text-xl md:text-left">
              <span className={`${i18n.language === 'th' ? 'text-2xl font-bold' : ''}`}>
                {t('shop')}
              </span>
            </div>
            <div className="text-center uppercase tracking-wide md:text-left">
              <span className={`${i18n.language === 'th' ? 'font-bold' : ''}`}>
                {t('shop_second')}
              </span>
            </div>
            <div className="text-center md:text-left">{t('shop_desc')}</div>
            <div className="text-center md:text-left">
              <button
                onClick={() => history.push(ROUTES.DISCOVER_SHOP)}
                className="mt-4 w-40 bg-gold px-9 py-2 text-center capitalize text-white"
              >
                {t('more_detail')}
              </button>
            </div>
          </div>
          <img className="pt-8" src="/images/discover/shop.jpg" alt="" />
        </div>
        <div className="flex flex-col items-center py-5 md:items-baseline md:px-10">
          <img className="pb-8" src="/images/discover/event.jpg" alt="" />
          <div className=" text-xl">
            <span className={`${i18n.language === 'th' ? 'text-2xl font-bold' : ''}`}>
              {t('event')}
            </span>
          </div>
          <div className="uppercase tracking-wide">
            <span className={`${i18n.language === 'th' ? 'font-bold' : ''}`}>
              {t('event_second')}
            </span>
          </div>
          <div className="items-center md:text-left">{t('event_desc')}</div>
          <button
            onClick={() => history.push(ROUTES.DISCOVER_EVENT)}
            className="mt-4 w-40 bg-gold px-9 py-2 text-center capitalize text-white"
          >
            {t('more_detail')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Discover;
