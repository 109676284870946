export function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, color: 'transparent', position: 'absolute' }}
      onClick={onClick}
    >
      <img className="rotate-180 transform" src="/images/slide-arrow.svg" alt="" />
    </div>
  );
}

export function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, color: 'transparent', position: 'absolute' }}
      onClick={onClick}
    >
      <img src="/images/slide-arrow.svg" alt="" />
    </div>
  );
}
