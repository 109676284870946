import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavbarMenu from '../../components/NavbarMenu';

const TermsAndCondition = () => {
  const { t } = useTranslation();
  const [isTerm1, setTerm1] = useState(false);
  const [isTerm2, setTerm2] = useState(false);
  const [isTerm3, setTerm3] = useState(false);
  const [isTerm4, setTerm4] = useState(false);
  const [isTerm5, setTerm5] = useState(false);
  const [isTerm6, setTerm6] = useState(false);
  const [isTerm7, setTerm7] = useState(false);
  const [isTerm8, setTerm8] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <>
      <NavbarMenu />
      <div className="py-10 px-5 font-modny md:px-20 xl:px-56 2xl:px-80">
        <div className="mb-10 flex justify-center text-lg md:text-xl">
          {t('terms_and_conditions')}
        </div>
        <div className="border p-2 md:p-10">
          <div className="mb-4 border-b">
            <div className="flex justify-between pb-4 text-lg font-bold">
              <div className={`${isTerm1 ? 'text-gold' : ''}`}>
                Terms & Conditions for Hotel MOCO Website ("Terms & Conditions")
              </div>
              <div onClick={() => setTerm1(!isTerm1)}>
                <svg
                  className={`h-7 w-7 ${isTerm1 ? 'rotate-180 transform text-gold' : ''}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            {isTerm1 && (
              <div className="px-10 pb-5 text-base">
                Please read these Terms carefully because these will apply to your use of our Site.
                By using our Site, you confirm that you accept these Terms and that you agree to
                comply with them. If you do not agree to these Terms, you must not use our Site.
              </div>
            )}
          </div>

          <div className="mb-4 border-b">
            <div className="flex justify-between pb-4 text-lg font-bold">
              <div className={`${isTerm2 ? 'text-gold' : ''}`}>Information about us</div>
              <div onClick={() => setTerm2(!isTerm2)}>
                <svg
                  className={`h-7 w-7 ${isTerm2 ? 'rotate-180 transform text-gold' : ''}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            {isTerm2 && (
              <div className="px-10 pb-5 text-base">
                This Site is operated by Hotel MOCO. We are registered in Thailand, Udonthani.{' '}
              </div>
            )}
          </div>

          <div className="mb-4 border-b">
            <div className="flex justify-between pb-4 text-lg font-bold">
              <div className={`${isTerm3 ? 'text-gold' : ''}`}>
                Changes to the terms and changes to our site
              </div>
              <div onClick={() => setTerm3(!isTerm3)}>
                <svg
                  className={`h-7 w-7 ${isTerm3 ? 'rotate-180 transform text-gold' : ''}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            {isTerm3 && (
              <div className="px-10 pb-5 text-base">
                We may revise the Terms by amending this page. Please check this page from time to
                time to take notice of any changes we made, as they are binding on you. <br />
                <br />
                We may update our Site from time to time, and may change the content at any time.
                However, please note that any of the content on our Site may be out of date at any
                given time, and we are under no obligation to update it. We do not guarantee that
                our Site, or any content on it, will be free from errors or omissions. <br />
                <br />
                Unless explicitly stated, any new features on the Site, including but not limited to
                the release of new services or resources shall be subject to these Terms.
              </div>
            )}
          </div>

          <div className="mb-4 border-b">
            <div className="flex justify-between pb-4 text-lg font-bold">
              <div className={`${isTerm4 ? 'text-gold' : ''}`}>Accessing our site</div>
              <div onClick={() => setTerm4(!isTerm4)}>
                <svg
                  className={`h-7 w-7 ${isTerm4 ? 'rotate-180 transform text-gold' : ''}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            {isTerm4 && (
              <div className="px-10 pb-5 text-base">
                We reserve the right to alter, suspend or discontinue any aspect of the Site or the
                services provided through it, including your access to it, without notice. We do not
                guarantee that our Site, or any content on it, will always be available or be
                uninterrupted. <br />
                <br />
                We will not be liable to you if for any reason our Site is unavailable at any time
                or for any period.
              </div>
            )}
          </div>

          <div className="mb-4 border-b">
            <div className="flex justify-between pb-4 text-lg font-bold">
              <div className={`${isTerm5 ? 'text-gold' : ''}`}>Intellectual property rights</div>
              <div onClick={() => setTerm5(!isTerm5)}>
                <svg
                  className={`h-7 w-7 ${isTerm5 ? 'rotate-180 transform text-gold' : ''}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            {isTerm5 && (
              <div className="px-10 pb-5 text-base">
                We are the owner or the licensee of all intellectual property rights in our Site,
                and in the material published on it. Those works are protected by copyright laws and
                treaties around the world. All such rights are reserved. <br />
                <br />
                You may print and download extracts, of any page from our Site for your personal use
                and you may draw the attention of others within your organisation to content posted
                on our Site. You must not modify the paper or digital copies of any materials you
                have printed or downloaded in any way, and you must not use any illustrations,
                photographs, video or audio sequences or any graphics separately from any
                accompanying text. Our status (and that of any identified contributors) as the
                authors of content on our Site must always be acknowledged.
              </div>
            )}
          </div>

          <div className="mb-4 border-b">
            <div className="flex justify-between pb-4 text-lg font-bold">
              <div className={`${isTerm6 ? 'text-gold' : ''}`}>No reliance on information</div>
              <div onClick={() => setTerm6(!isTerm6)}>
                <svg
                  className={`h-7 w-7 ${isTerm6 ? 'rotate-180 transform text-gold' : ''}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            {isTerm6 && (
              <div className="px-10 pb-5 text-base">
                We are the owner or the licensee of all intellectual property rights in our Site,
                and in the material published on it. Those works are protected by copyright laws and
                treaties around the world. All such rights are reserved. <br />
                <br />
                You may print and download extracts, of any page from our Site for your personal use
                and you may draw the attention of others within your organisation to content posted
                on our Site. You must not modify the paper or digital copies of any materials you
                have printed or downloaded in any way, and you must not use any illustrations,
                photographs, video or audio sequences or any graphics separately from any
                accompanying text. Our status (and that of any identified contributors) as the
                authors of content on our Site must always be acknowledged.
              </div>
            )}
          </div>

          <div className="mb-4 border-b">
            <div className="flex justify-between pb-4 text-lg font-bold">
              <div className={`${isTerm7 ? 'text-gold' : ''}`}>Viruses</div>
              <div onClick={() => setTerm7(!isTerm7)}>
                <svg
                  className={`h-7 w-7 ${isTerm7 ? 'rotate-180 transform text-gold' : ''}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            {isTerm7 && (
              <div className="px-10 pb-5 text-base">
                We do not guarantee that our Site will be secure or free from bugs or viruses. You
                are responsible for configuring your information technology, computer programmes and
                platform in order to access our Site. You should use your own virus protection
                software. <br />
                <br />
                You must not misuse our Site by knowingly introducing viruses, trojans, worms, logic
                bombs or other material which is malicious or technologically harmful. You must not
                attempt to gain unauthorised access to our Site, the server on which our Site is
                stored or any server, computer or database connected to our Site. You must not
                attack our Site via a denial-of-service attack or a distributed denial-of service
                attack. By breaching this provision, you would commit a criminal offence under the
                Computer Misuse Act 1990. We will report any such breach to the relevant law
                enforcement authorities and we will co-operate with those authorities by disclosing
                your identity to them. In the event of such a breach, your right to use our Site
                will cease immediately.{' '}
              </div>
            )}
          </div>

          <div className="mb-4 border-b">
            <div className="flex justify-between pb-4 text-lg font-bold">
              <div className={`${isTerm8 ? 'text-gold' : ''}`}>Cookies and how they are used</div>
              <div onClick={() => setTerm8(!isTerm8)}>
                <svg
                  className={`h-7 w-7 ${isTerm8 ? 'rotate-180 transform text-gold' : ''}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            {isTerm8 && (
              <div className="px-10 pb-5 text-base">
                We use necessary cookies to make our site work. We’d also like to set optional
                analytics and marketing cookies. We won't set these cookies unless you choose to
                turn these cookies on. Using this tool will also set a cookie on your device to
                remember your preferences. <br />
                <br />
                Please be aware: <br />
                <br />
                — If you delete all your cookies you will have to update your preferences with us
                again. <br />
                <br />
                — If you use a different device or browser you will have to tell us your preferences
                again. <br />
                <br />
                We use the following types of cookies, which are described below :<br />
                <br />
                <table className="table-fixed border">
                  <thead>
                    <tr>
                      <th className="w-1/3 border p-4 text-left">Type of cookie</th>
                      <th className="w-2/3 border p-4 text-left">What does it do?</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border p-4 align-top">Strictly necessary cookies</td>
                      <td className="border p-4">
                        These cookies are essential to enable you to move around the Website and use
                        its features, such as accessing secure areas of the Website. We use strictly
                        necessary cookies to make sure our digital services work securely, correctly
                        and perform their basic functions.
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-4 align-top">Security cookies</td>
                      <td className="border p-4">
                        We use security cookies to make your interaction with our services faster
                        and more secure. These cookies will help identify and prevent potential
                        security risks.
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-4 align-top">Functionality cookies</td>
                      <td className="border p-4">
                        These cookies allow the Website to recognize you and remember your
                        preferences (such as your language of choice or the region you are in) and
                        provide a more personalized experience. These cookies can also be used to
                        remember changes you have made to text size, fonts and other parts of the
                        web pages that you can customise.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;
