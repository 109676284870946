import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NavbarMenu from '../../../components/NavbarMenu';
import Subscribe from '../../../components/Subscribe';
import TitleSection from '../../../components/TitleSection';

const Event = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  });
  return (
    <>
      <NavbarMenu />
      <div className="w-full bg-greenYellowLight">
        <div className="px-5 pt-5 md:px-10 xl:px-20 2xl:px-40">
          <TitleSection title={t('menu_discover_event')} />
        </div>
        <div className="py-10 px-5 md:px-20 xl:px-56 2xl:px-80">
          <div className="flex flex-col items-center md:grid md:grid-cols-3">
            <div className="col-span-2">
              <img className="w-full md:p-10" src="/images/event/event_valentine.jpg" alt="" />
            </div>
            <div className="col-span-1 p-4 text-center md:p-10 md:text-left">
              <div className="text-3xl md:py-4">{t('event_valentine')}</div>
              <div>{t('event_valentine_desc')}</div>
            </div>
          </div>
          <div className="flex flex-col-reverse items-center md:grid md:grid-cols-3">
            <div className="col-span-1 p-4 text-center md:p-10 md:text-left">
              <div className="text-3xl md:py-4">{t('event_halloween')}</div>
              <div>{t('event_halloween_desc')}</div>
            </div>
            <div className="col-span-2">
              <img className="w-full md:p-10" src="/images/event/event_halloween.jpg" alt="" />
            </div>
          </div>
          <div className="flex flex-col items-center md:grid md:grid-cols-3">
            <div className="col-span-2">
              <img className="w-full md:p-10" src="/images/event/event_christmas.jpg" alt="" />
            </div>
            <div className="col-span-1 p-4 text-center md:p-10 md:text-left">
              <div className="text-3xl md:py-4">{t('event_christmas')}</div>
              <div>{t('event_christmas_desc')}</div>
            </div>
          </div>
          <div className="flex flex-col-reverse items-center md:grid md:grid-cols-3">
            <div className="col-span-1 p-4 text-center md:p-10 md:text-left">
              <div className="text-3xl md:py-4">{t('event_newyear')}</div>
              <div>{t('event_newyear_desc')}</div>
            </div>
            <div className="col-span-2">
              <img className="w-full md:p-10" src="/images/event/event_newyear.jpg" alt="" />
            </div>
          </div>
          <div className="flex flex-col items-center md:grid md:grid-cols-3">
            <div className="col-span-2">
              <img className="w-full md:p-10" src="/images/event/event_newyear_th.jpg" alt="" />
            </div>
            <div className="col-span-1 p-4 text-center md:p-10 md:text-left">
              <div className="text-3xl md:py-4">{t('event_th_newyear')}</div>
              <div>{t('event_th_newyear_desc')}</div>
            </div>
          </div>
        </div>
        <Subscribe />
      </div>
    </>
  );
};

export default Event;
