import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import NavbarMenu from '../../../components/NavbarMenu';
import { NextArrow, PrevArrow } from '../../../utils/slider-arrow';
import { SOCIAL_URL } from '../../../utils/social-url';

const Shop = () => {
  const { t } = useTranslation();
  const settings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    slidesToShow: 1,
    initialSlide: 0,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  });
  return (
    <>
      <NavbarMenu />
      <div className="relative my-2 w-full px-10 md:my-4 md:px-20">
        <div className="child-center absolute bg-white px-4 text-lg uppercase tracking-widest md:text-2xl">
          {t('uptown')}
        </div>
        <div className="flex h-7 items-center">
          <div className="h-1 w-full border-t-2 border-greenBrowLight"></div>
        </div>
      </div>
      <div className="w-full py-5 px-5 md:px-20 xl:px-56 2xl:px-80">
        <div className="single-slide px-0 md:px-40">
          <Slider {...settings}>
            <div>
              <img className="w-full" src="/images/shop/shop_1.jpg" alt="" />
            </div>
            <div>
              <img className="w-full" src="/images/shop/shop_2.jpg" alt="" />
            </div>
            <div>
              <img className="w-full" src="/images/shop/shop_3.jpg" alt="" />
            </div>
            <div>
              <img className="w-full" src="/images/shop/shop_4.jpg" alt="" />
            </div>
            <div>
              <img className="w-full" src="/images/shop/shop_5.jpg" alt="" />
            </div>
          </Slider>
        </div>
        <div className="flex flex-col items-center md:grid md:grid-cols-3 md:items-baseline md:px-20">
          <div className="col-span-1 px-0 py-5 text-center md:px-10 md:text-left">
            <div className="text-3xl text-green">{t('uptown_title')}</div>
          </div>
          <div className="col-span-2">
            <div className="text-center md:text-left">{t('uptown_desc')}</div>
            <div className="flex justify-end">
              <img
                className="mx-4 cursor-pointer"
                src="/images/ic-facebook.svg"
                alt=""
                onClick={() => window.open(SOCIAL_URL.UPTOWN.facebook)}
              />
              <img
                className="cursor-pointer"
                src="/images/ic-instagram.svg"
                alt=""
                onClick={() => window.open(SOCIAL_URL.UPTOWN.instagram)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-greenYellowLight px-5 pb-10 md:py-10 md:px-20">
        <div className="flex flex-col items-center md:grid md:grid-cols-3">
          <div className="col-span-1 p-2 text-center md:p-10 md:text-left">
            <div className="py-4 text-3xl text-green">{t('plearn_spa')}</div>
            <div>{t('plearn_spa_desc')}</div>
          </div>
          <div className="col-span-2">
            <img className="w-full md:p-20" src="/images/shop/plearn-spa.jpeg" alt="" />
            <div className="mt-4 flex justify-end md:mt-0 md:px-20">
              <img
                className="mx-4 cursor-pointer"
                src="/images/ic-facebook.svg"
                alt=""
                onClick={() => window.open(SOCIAL_URL.PLEARN_SPA.facebook)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shop;
