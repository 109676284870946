import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import i18n from '../utils/i18n';

interface IForm {
  onValidated: any;
  message?: any;
  status?: any;
}
const CustomForm: React.FC<IForm> = ({ message, onValidated }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const submit = () =>
    email &&
    email.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email,
    });

  return (
    <>
      <div className="mail-chimp flex flex-col md:w-3/5 md:flex-row">
        <input
          className="mr-3 mb-2 h-10 w-full px-4 md:mb-0 md:w-3/5"
          type="text"
          placeholder={t('your_email')}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          disabled={email ? false : true}
          className={`${
            i18n.language === 'th' && 'text-2xl'
          } w-full bg-gold px-10 py-1 uppercase text-white md:w-auto`}
          onClick={submit}
        >
          {t('subscribe')}
        </button>
      </div>
      <div className="pt-2">{message}</div>
    </>
  );
};

const Subscribe = () => {
  const { t } = useTranslation();
  const url =
    'https://hotelmoco.us5.list-manage.com/subscribe/post?u=318941cb6512ce6a2a7a19d75&amp;id=68f714cf3c';

  // const SimpleForm = () => <MailchimpSubscribe url={url}/>
  return (
    <div className="flex h-80 w-full flex-col justify-center bg-greenExtraLight px-5 md:px-20 xl:px-56 2xl:px-80">
      <div
        className={`${
          i18n.language === 'th' ? 'text-2xl md:text-3xl' : 'text-xl'
        } font-bold text-green md:w-3/5`}
      >
        {t('subscribe_desc')}
        {i18n.language === 'th' && <span className="font-modny text-lg"> Hotel MOCO </span>}
      </div>
      <div className={`${i18n.language === 'th' && 'text-2xl'} pb-4 pt-2`}>
        {t('cookie_consent')}
      </div>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData: any) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default Subscribe;
