import './App.css';
import './utils/i18n';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import Footer from './components/Footer';
import ScrollTop from './components/ScrollTop';
import { ScrollingProvider } from 'react-scroll-section';
import BookBtn from './components/BookBtn';
import i18n from './utils/i18n';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import CookieModal from './components/CookieModal';
import { MocoContextProvider } from './context/MocoContext';
import { ROUTES } from './utils/constant';
import { ToastProvider } from 'react-toast-notifications';

function App() {
  const [isOpenAcceptCookie, setOpenAcceptCookie] = useState(false);
  const [lang, setLang] = useState<string>(i18n.language);
  const onScrollToTop = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  useEffect(() => {
    i18next.on('languageChanged', () => {
      if (i18n.language !== lang) {
        setLang(i18n.language);
      }
    });
  });

  const handleAcceptCookie = () => {
    setOpenAcceptCookie(false);
    localStorage.setItem('isAcceptCookie', 'true');
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    const cookie = localStorage.getItem('isAcceptCookie');
    if (!cookie && pathname !== ROUTES.TERMS_AND_CONDITIONS) {
      setOpenAcceptCookie(true);
    }
  }, []);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ToastProvider>
        <MocoContextProvider>
          <ScrollingProvider scrollBehavior="smooth">
            <div className={`${lang === 'en' ? 'font-modny text-sm' : 'font-saraban text-xl'}`}>
              <Router />
              <Footer />
              <BookBtn />
              <div onClick={onScrollToTop} className="flex md:hidden">
                <ScrollTop />
              </div>
              {isOpenAcceptCookie && <CookieModal OnAccept={handleAcceptCookie} />}
            </div>
          </ScrollingProvider>
        </MocoContextProvider>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
