import DesktopMenu from './header/desktop';
import MobileMenu from './header/mobile';

const MAXIMUM_WIDTH = 768;

const NavbarMenu = () => {
  return (
    <>
      <div className="flex md:hidden">
        <MobileMenu />
      </div>
      <div className="sticky z-10 hidden md:flex">
        <DesktopMenu />
      </div>
      {window.screen.width > MAXIMUM_WIDTH && <div className="h-32"></div>}
    </>
  );
};

export default NavbarMenu;
