import { useTranslation } from 'react-i18next';

const BookBtn = () => {
  const { t } = useTranslation();
  return (
    <div
      className="fixed bottom-0 left-0 z-10 flex h-16 w-full items-center justify-center bg-gold text-xl text-white  md:hidden"
      onClick={() =>
        window.open('https://book-directonline.com/properties/montatiphoteldirect', '_blank')
      }
    >
      {t('book_now')}
    </div>
  );
};

export default BookBtn;
