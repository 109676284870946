import { useTranslation } from 'react-i18next';

const VideoTitle: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="relative h-full w-full  tracking-modny">
      <video autoPlay playsInline muted loop id="myVideo" className="w-screen">
        <source src="/videos/moco-video.mp4" type="video/mp4" />
      </video>
      <div className="absolute top-0 h-full w-full bg-black bg-opacity-50">
        <div className="flex h-full w-full flex-col items-center justify-center">
          <img className="w-40" src="/images/logo-name.svg" alt="" />
          <div className="txt-white hidden pt-40 text-center font-modny text-7xl md:flex">
            {t('title_msg')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoTitle;
