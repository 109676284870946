import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import NavbarMenu from '../../components/NavbarMenu';
import { ROUTES } from '../../utils/constant';
import i18n from '../../utils/i18n';
import Deluxe from './deluxe';
import JuniorSuite from './junior-suite';
import MocoSuite from './moco-suite';
import Superior from './superior';

const RoomPage = () => {
  const history = useHistory();
  const params = useParams<any>();
  const { t } = useTranslation();
  const [initRoom, setRoom] = useState<string>('');

  useEffect(() => {
    const initSlide = params && params.id ? params.id : 'superior';
    setRoom(initSlide);
  }, [params]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  });

  return (
    <>
      <NavbarMenu />
      <div className="w-full">
        <div className="mt-2 grid grid-cols-2 px-0 md:grid-cols-4 md:px-20 lg:px-40 xl:px-60">
          <button
            onClick={() => {
              setRoom('superior');
              history.push(ROUTES.ROOMS_SUPERIOR);
            }}
            className={`${
              i18n.language === 'th' ? 'text-2xl' : 'text-sm md:text-lg'
            } w-full border-2 border-white bg-gold py-2 text-center text-white md:px-9 ${
              initRoom !== 'superior' ? 'disable' : ''
            }`}
          >
            {t('menu_room_superior')}
          </button>
          <button
            onClick={() => {
              setRoom('deluxe');
              history.push(ROUTES.ROOMS_DELUXE);
            }}
            className={`${
              i18n.language === 'th' ? 'text-2xl' : 'text-sm md:text-lg'
            } w-full border-2 border-white bg-gold py-2 text-center text-white md:px-9 ${
              initRoom !== 'deluxe' ? 'disable' : ''
            }`}
          >
            {t('menu_room_deluxe')}
          </button>
          <button
            onClick={() => {
              setRoom('junior-suite');
              history.push(ROUTES.ROOMS_JUNIOR_SUITE);
            }}
            className={`${
              i18n.language === 'th' ? 'text-2xl' : 'text-sm md:text-lg'
            } w-full border-2 border-white bg-gold py-2 text-center text-white md:px-9 ${
              initRoom !== 'junior-suite' ? 'disable' : ''
            }`}
          >
            {t('menu_room_junior_suite')}
          </button>
          <button
            onClick={() => {
              setRoom('moco-suite');
              history.push(ROUTES.ROOMS_MOCO_SUITE);
            }}
            className={`${
              i18n.language === 'th' ? 'text-2xl' : 'text-sm md:text-lg'
            } w-full border-2 border-white bg-gold py-2 text-center text-white md:px-9 ${
              initRoom !== 'moco-suite' ? 'disable' : ''
            }`}
          >
            {t('menu_room_moco_suite')}
          </button>
        </div>
        {initRoom === 'superior' && <Superior />}
        {initRoom === 'deluxe' && <Deluxe />}
        {initRoom === 'junior-suite' && <JuniorSuite />}
        {initRoom === 'moco-suite' && <MocoSuite />}
      </div>
    </>
  );
};

export default RoomPage;
