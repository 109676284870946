import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from './../locales/en/en.json';
import TH from './../locales/th/th.json';
import CN from './../locales/cn/cn.json';
import LanguageDetector from 'i18next-browser-languagedetector';

export const resources = {
  en: { translation: EN },
  th: { translation: TH },
  cn: { translation: CN },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    // lng: 'th', //remove when use multilang
    supportedLngs: ['en', 'th'],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
