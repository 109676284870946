import dayjs from 'dayjs';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import i18n from '../utils/i18n';

const BookSection = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const onReserve = () => {
    const start = dayjs(startDate).format('YYYY-MM-DD');
    const end = dayjs(endDate).format('YYYY-MM-DD');
    window.open(
      `https://book-directonline.com/properties/montatiphoteldirect?check_in_date=${start}&check_out_date=${end}`,
      '_blank',
    );
  };
  return (
    <div className="mb-16 md:mb-0">
      <div className="grid w-full grid-cols-1 items-center bg-green px-5 py-14 text-white md:grid-cols-5 md:px-20 xl:px-56 2xl:px-80">
        <div className="col-span-1 pr-0 md:col-span-2 md:pr-10">
          <div className="pb-2 text-center text-2xl font-bold md:pb-4 md:text-left md:text-4xl">
            {t('book_title')}
          </div>
          <div
            className={`${
              i18n.language !== 'th' && 'text-base'
            }  pb-2 text-center md:pb-0 md:text-left`}
          >
            {t('book_desc')} <span className="font-modny">Hotel MOCO</span> {t('book_desc_now')}
          </div>
        </div>
        <div className="grid-cols-3 flex-row items-end md:col-span-3 md:grid">
          <div className="pr-0 pb-1 md:pr-2 md:pb-0">
            {(startDate || endDate) && (
              <span className={`${i18n.language === 'th' && 'text-xl'}`}>{t('check_in')}</span>
            )}
            <DatePicker
              className="w-full"
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              minDate={new Date()}
              maxDate={endDate}
              placeholderText={t('check_in')}
            />
          </div>
          <div className="pr-0 pb-4 md:pr-2 md:pb-0">
            {(startDate || endDate) && (
              <span className={`${i18n.language === 'th' && 'text-xl'}`}>{t('check_out')}</span>
            )}
            <DatePicker
              className="w-full"
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              minDate={startDate}
              placeholderText={t('check_out')}
            />
          </div>
          <div className="px-0 md:px-2">
            <button
              disabled={!startDate || !endDate}
              className="w-full bg-gold py-2 text-center text-white"
              onClick={onReserve}
            >
              {t('reserve')}
            </button>
          </div>
          {window.screen.width > 767 && <div id="gallery"></div>}
        </div>
      </div>
    </div>
  );
};

export default BookSection;
