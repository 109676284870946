import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import NavbarMenu from '../../components/NavbarMenu';
import i18n from '../../utils/i18n';
import { NextArrow, PrevArrow } from '../../utils/slider-arrow';
import { SOCIAL_URL } from '../../utils/social-url';

const Facilities = () => {
  const { t } = useTranslation();
  const settings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    slidesToShow: 1,
    initialSlide: 0,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  });
  return (
    <>
      <NavbarMenu />
      <div className="relative my-2 w-full px-10 md:my-4 md:px-20">
        <div className="child-center absolute bg-white px-4 text-lg uppercase tracking-widest md:text-2xl">
          {t('menu_facilities')}
        </div>
        <div className="flex h-7 items-center">
          <div className="h-1 w-full border-t-2 border-greenBrowLight"></div>
        </div>
      </div>
      <div className="w-full px-5 pt-4 md:px-20 xl:px-56 2xl:px-80">
        <div className="flex justify-center py-4 px-0 md:px-40">
          <img src="/images/facilities/moco-studio.JPG" alt="" />
        </div>
        <div className="flex flex-col py-8 px-0 md:grid md:grid-cols-2 md:px-20">
          <div className="col-span-1 px-0 text-center md:px-10 md:text-left">
            <div className=" text-3xl text-green">{t('moco_studio')}</div>
          </div>
          <div
            className="col-span-1 text-center md:text-left"
            dangerouslySetInnerHTML={{ __html: t('moco_studio_desc') }}
          ></div>
        </div>
      </div>
      <div className="w-full bg-greenYellowLight py-10 px-5 md:px-20 xl:px-56 2xl:px-80">
        <div className="flex justify-center font-modny text-3xl tracking-wide text-green">
          {t('montatip_hall')}
        </div>
        <div className="single-slide py-4 px-2 md:px-40">
          <Slider {...settings}>
            <div>
              <img className="w-full" src="/images/facilities/montathip_1.jpg" alt="" />
            </div>
            <div>
              <img className="w-full" src="/images/facilities/montathip_2.jpg" alt="" />
            </div>
            <div>
              <img className="w-full" src="/images/facilities/montathip_3.jpg" alt="" />
            </div>
            <div>
              <img className="w-full" src="/images/facilities/montathip_4.jpg" alt="" />
            </div>
            <div>
              <img className="w-full" src="/images/facilities/montathip_5.jpg" alt="" />
            </div>
          </Slider>
        </div>
        <div className="flex flex-col md:grid md:grid-cols-2">
          <div className="col-span-1 px-10 text-center md:text-left">
            <div className="font-modny text-base  tracking-wide text-green md:text-2xl">
              {t('montatip_hall_detail')}
            </div>
          </div>
          <div className="col-span-1 px-4 text-center md:px-0 md:text-left">
            {t('montatip_hall_desc')}
          </div>
        </div>
        {i18n.language === 'en' && (
          <div className="list-desc px-10 py-4">
            <ul>
              <li className="pb-2">{t('montatip_hall_detail_1')}</li>
              <li className="pb-2">{t('montatip_hall_detail_2')}</li>
              <li className="pb-2">{t('montatip_hall_detail_3')}</li>
              <li className="pb-2">{t('montatip_hall_detail_4')}</li>
            </ul>
            <div className="indent-8 pt-2">{t('montatip_hall_footer_detail')}</div>
          </div>
        )}
        <div className="mt-4 flex justify-end">
          <img
            className="mx-4 cursor-pointer"
            src="/images/ic-facebook.svg"
            alt=""
            onClick={() => window.open(SOCIAL_URL.MONTATIP_HALL.facebook)}
          />
          {/* <img className="cursor-pointer" src="/images/ic-instagram.svg" alt="" /> */}
          <img
            className="mx-4 w-5 cursor-pointer"
            src="/images/ic-web.svg"
            alt=""
            onClick={() => window.open(SOCIAL_URL.MONTATIP_HALL.web)}
          />
        </div>
      </div>
    </>
  );
};

export default Facilities;
