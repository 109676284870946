import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROUTES } from '../utils/constant';

interface IModal {
  OnAccept: () => any;
}
const CookieModal: React.FC<IModal> = ({ OnAccept }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleTerms = () => {
    history.push(ROUTES.TERMS_AND_CONDITIONS);
  };
  return (
    <div className="alige-center fixed right-4 top-1/2 left-4 z-10  transform text-white md:right-auto md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2">
      <div className="inline-block transform overflow-hidden rounded-lg bg-greenModal text-left align-bottom font-modny shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
        <div className="bg-greenModal px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg font-medium leading-6 " id="modal-title">
                {t('cookie_title')}
              </h3>
              <div className="mt-2">
                <p className="text-sm ">{t('cookie_desc')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center bg-greenModal px-4 py-3 sm:px-6 md:flex-row">
          <div
            className="cursor-pointer pb-4 text-center text-base underline md:py-0 md:px-5"
            onClick={handleTerms}
          >
            {t('terms_and_conditions')}
          </div>
          <button
            type="button"
            onClick={OnAccept}
            className="inline-flex w-full justify-center rounded-md bg-gold px-4 py-2 text-base font-medium uppercase tracking-wide text-white sm:ml-3 sm:w-auto sm:text-sm"
          >
            {t('accept_all_cookie')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieModal;
