import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NavbarMenu from '../../../components/NavbarMenu';
import TitleSection from '../../../components/TitleSection';

const CultureTradition = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  });
  return (
    <>
      <NavbarMenu />
      <div className="w-full bg-greenYellowLight">
        <div className="px-5 pt-5 md:px-10 xl:px-20 2xl:px-40">
          <TitleSection title={t('recreation')} />
        </div>
        <div className="px-5 pt-2 pb-5 md:px-20 xl:px-56 2xl:px-80">
          <div className="flex flex-col-reverse items-center md:grid md:grid-cols-3">
            <div className="col-span-2 pb-2">
              <img
                className="mb-2 w-full p-2 md:mb-0 md:p-10"
                src="/images/recreation/recreation_1.jpg"
                alt=""
              />
            </div>
            <div className="col-span-1 text-center md:text-left">
              <div className="py-2 text-3xl md:py-4">{t('recreation_title1')}</div>
              <div>{t('recreation_desc1')}</div>
            </div>
          </div>
          <div className="flex flex-col items-center md:grid md:grid-cols-3">
            <div className="col-span-1 text-center md:text-left">
              <div className="py-2 text-3xl md:py-4">{t('recreation_title2')}</div>
              <div>{t('recreation_desc2')}</div>
            </div>
            <div className="col-span-2 pb-2">
              <img
                className="mb-2 w-full p-2 md:mb-0 md:p-10"
                src="/images/recreation/recreation_2.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="flex flex-col-reverse items-center md:grid md:grid-cols-3">
            <div className="col-span-2 pb-2">
              <img
                className="mb-2 w-full p-2 md:mb-0 md:p-10"
                src="/images/recreation/recreation_3.jpg"
                alt=""
              />
            </div>
            <div className="col-span-1 text-center md:text-left">
              <div className="py-2 text-3xl md:py-4">{t('recreation_title3')}</div>
              <div>{t('recreation_desc3')}</div>
            </div>
          </div>
          <div className="flex flex-col items-center md:grid md:grid-cols-3">
            <div className="col-span-1 text-center md:text-left">
              <div className="py-2 text-3xl md:py-4">{t('recreation_title4')}</div>
              <div>{t('recreation_desc4')}</div>
            </div>
            <div className="col-span-2 pb-2">
              <img
                className="mb-2 w-full p-2 md:mb-0 md:p-10"
                src="/images/recreation/recreation_4.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="flex flex-col-reverse items-center md:grid md:grid-cols-3">
            <div className="col-span-2 pb-2">
              <img
                className="mb-2 w-full p-2 md:mb-0 md:p-10"
                src="/images/recreation/recreation_5.jpg"
                alt=""
              />
            </div>
            <div className="col-span-1 text-center md:text-left">
              <div className="py-2 text-3xl md:py-4">{t('recreation_title5')}</div>
              <div>{t('recreation_desc5')}</div>
            </div>
          </div>
          <div className="flex flex-col items-center md:grid md:grid-cols-3">
            <div className="col-span-1 text-center md:text-left">
              <div className="py-2 text-3xl md:py-4">{t('recreation_title6')}</div>
              <div>{t('recreation_desc6')}</div>
            </div>
            <div className="col-span-2 pb-2">
              <img
                className="mb-2 w-full p-2 md:mb-0 md:p-10"
                src="/images/recreation/recreation_6.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CultureTradition;
