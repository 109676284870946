export const ROUTES = {
  MAIN: '/',
  HOME: '/',
  OUR_STORY: '/?section=our-story',
  ROOMS: '/rooms/:id',
  ROOMS_SUPERIOR: '/rooms/superior',
  ROOMS_DELUXE: '/rooms/deluxe',
  ROOMS_JUNIOR_SUITE: '/rooms/junior-suite',
  ROOMS_MOCO_SUITE: '/rooms/moco-suite',
  DISCOVER: '/discover/:id',
  DISCOVER_CULTURE: '/culture',
  DISCOVER_SHOP: '/shop',
  DISCOVER_EVENT: '/event',
  DINING: '/#dining',
  DINING_PAR: '/?section=par',
  DINING_BAR: '/?section=bar',
  DINING_BRANCH: '/?section=branch',
  FACILITY: '/facilities',
  CONTACT_US: '/contact-us',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  GALLERY: '/?section=gallery',
};

export const languages: Record<string, string> = {
  en: 'English',
  th: 'Thai',
  // cn: 'Chinese',
};

export const menus: Record<string, string> = {
  [ROUTES.HOME]: 'menu_home',
  [ROUTES.OUR_STORY]: 'menu_our_story',
  [ROUTES.ROOMS]: 'menu_room',
  [ROUTES.DISCOVER]: 'menu_discover',
  [ROUTES.DINING]: 'menu_dining',
  [ROUTES.FACILITY]: 'menu_facilities',
  [ROUTES.CONTACT_US]: 'menu_contact_us',
  [ROUTES.GALLERY]: 'menu_gallery',
};

export const menusRooms: Record<string, string> = {
  [ROUTES.ROOMS_SUPERIOR]: 'menu_room_superior',
  [ROUTES.ROOMS_DELUXE]: 'menu_room_deluxe',
  [ROUTES.ROOMS_JUNIOR_SUITE]: 'menu_room_junior_suite',
  [ROUTES.ROOMS_MOCO_SUITE]: 'menu_room_moco_suite',
};

export const menuDiscover: Record<string, string> = {
  [ROUTES.DISCOVER_CULTURE]: 'menu_discover_culture',
  [ROUTES.DISCOVER_SHOP]: 'menu_discover_shop',
  [ROUTES.DISCOVER_EVENT]: 'menu_discover_event',
};

export const menuDining: Record<string, string> = {
  [ROUTES.DINING_PAR]: 'menu_dining_par',
  [ROUTES.DINING_BAR]: 'menu_dining_bar',
  [ROUTES.DINING_BRANCH]: 'menu_dining_branch',
};
