import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Gallery = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const script: HTMLScriptElement = document.createElement('script');
    script.src = 'https://cdn2.woxo.tech/a.js#619b98551198e500166b9a45';
    script.async = true;
    script.setAttribute('data-usrc', '');
    document.body.appendChild(script);
  }, []);

  return (
    <div className="w-full bg-opacity-20 py-10">
      <div className="relative">
        <div className="relative my-4 w-full px-5 md:px-20 xl:px-56 2xl:px-80">
          <div className="child-center absolute bg-white px-4 text-lg uppercase tracking-widest md:text-2xl">
            {t('section_share')}
          </div>
          <div className="flex h-7 items-center">
            <div className="h-1 w-full border-t-2 border-greenBrowLight"></div>
          </div>
        </div>
        <div className="w-full text-center text-3xl  text-green">{t('share_title')}</div>
        <div className="w-full text-center text-lg">{t('share_desc')}</div>
        <div className="relative px-2">
          <div data-mc-src="3b449c15-c6ab-430d-bf08-111f564472ca#instagram"></div>
        </div>
        <div className="absolute bottom-0 h-6 w-full bg-white"></div>
      </div>
    </div>
  );
};

export default Gallery;
