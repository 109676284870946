import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROUTES } from '../utils/constant';
import { SOCIAL_URL } from '../utils/social-url';

const Footer = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="mb-16 md:mb-0">
      <div className="md:min-h-64 flex h-auto w-full flex-col items-center bg-green py-6 text-white md:grid md:grid-cols-5">
        <div className="col-span-2 flex justify-center py-10 md:py-0">
          <img
            className="w-40 cursor-pointer"
            src="/images/logo-name.svg"
            alt=""
            onClick={() => history.push(ROUTES.HOME)}
          />
        </div>
        <div className="col-span-3 flex flex-col place-content-between text-center md:grid md:grid-cols-4 md:text-left">
          <div className="col-span-1">
            <div className="w-full cursor-pointer" onClick={() => history.push(ROUTES.CONTACT_US)}>
              {t('menu_contact_us')}
            </div>
            <div
              className="w-full cursor-pointer font-modny text-base"
              onClick={() => history.push(ROUTES.TERMS_AND_CONDITIONS)}
            >
              {t('terms_and_conditions')}
            </div>
          </div>
          <div className="col-span-1">
            <div
              className="w-full cursor-pointer font-modny text-base"
              onClick={() => window.open(SOCIAL_URL.UPTOWN.facebook, '_blank')}
            >
              {t('footer_town')}
            </div>
            <div
              className="w-full cursor-pointer font-modny text-base"
              onClick={() => window.open(SOCIAL_URL.MONTATIP_HALL.web, '_blank')}
            >
              {t('footer_hall')}
            </div>
          </div>
          <div className="col-span-2 flex flex-col md:pr-28">
            <div className="font-modny text-base">{t('footer_address')}</div>
            <div className="tracking-wide">{t('contact_address')}</div>
            <div>{t('contact_tel')}: 042 219 456</div>
            <div>
              {t('contact_facebook')}:{' '}
              <span
                className="cursor-pointer"
                onClick={() => window.open(SOCIAL_URL.MOCO.facebook)}
              >
                HotelmocoUdonthani
              </span>{' '}
            </div>
            <div>{t('contact_line')}: @hotelmoco</div>
            <div>
              {t('contact_instagram')}:{' '}
              <span
                className="cursor-pointer"
                onClick={() => window.open(SOCIAL_URL.MOCO.instagram)}
              >
                hotelmocoudonthani
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
