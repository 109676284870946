import { useEffect } from 'react';
import { useLocation } from 'react-router';
import BookSection from '../../components/BookSection';
import Header from '../../components/header';
import RoomsGallery from '../../components/RoomsGallery';
import Gallery from '../../components/sections/gallery';
import Home from '../../components/sections/home';
import Discover from '../../components/sections/ourstory/discover';
import Energise from '../../components/sections/ourstory/energies';
import Explore from '../../components/sections/ourstory/explore';
import Subscribe from '../../components/Subscribe';

const MainPage = () => {
  const location = useLocation().search;
  useEffect(() => {
    const params = new URLSearchParams(location);
    const section = params.get('section');
    if (section) {
      const gallery = document.getElementById(section);
      if (gallery) {
        const delay = window.screen.width > 767 ? 0 : 100;

        setTimeout(() => {
          gallery.scrollIntoView(true);
        }, delay);
      }
    } else {
      window.scrollTo({ top: 0 });
    }
  }, [location]);
  return (
    <>
      <Header />
      <Home />
      <div className="w-full bg-greenYellowLight px-5 pt-10 md:px-20 xl:px-56 2xl:px-80">
        <div id="our-story">
          <div className="hidden h-28 md:flex"></div>
          <Explore />
        </div>
        <Discover />
      </div>
      <div className="w-full bg-greenYellowLight px-5 pt-10 md:px-20 xl:px-56 2xl:px-80">
        <div id="par"></div>
        <div id="bar"></div>
        <div id="branch"></div>
        <div className="hidden h-28 md:flex"></div>
        <Energise />
      </div>
      <RoomsGallery />
      <BookSection />
      {window.screen.width <= 767 && <div id="gallery"></div>}
      <Gallery />
      <Subscribe />
    </>
  );
};

export default MainPage;
