import { Switch, Route, Redirect } from 'react-router-dom';
import RoomPage from './pages/rooms';
import ContactPage from './pages/contact';
import MainPage from './pages/main';
import { ROUTES } from './utils/constant';
import CultureTradition from './pages/discover/culture-tradition';
import Shop from './pages/discover/shop';
import Event from './pages/discover/event';
import Facilities from './pages/facilities';
import TermsAndCondition from './pages/terms-and-condition';

const Router = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.MAIN} component={MainPage} />
      <Route exact path={ROUTES.ROOMS} component={RoomPage} />
      <Route exact path={ROUTES.DISCOVER_CULTURE} component={CultureTradition} />
      <Route exact path={ROUTES.DISCOVER_SHOP} component={Shop} />
      <Route exact path={ROUTES.DISCOVER_EVENT} component={Event} />
      <Route exact path={ROUTES.FACILITY} component={Facilities} />
      <Route exact path={ROUTES.CONTACT_US} component={ContactPage} />
      <Route exact path={ROUTES.TERMS_AND_CONDITIONS} component={TermsAndCondition} />
      <Route
        render={() => {
          return <Redirect to={ROUTES.MAIN} />;
        }}
      />
    </Switch>
  );
};

export default Router;
