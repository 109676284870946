import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import Complimentary from '../../../components/Complimentary';
import i18n from '../../../utils/i18n';
import { NextArrow, PrevArrow } from '../../../utils/slider-arrow';

const MocoSuite = () => {
  const { t } = useTranslation();
  const sliderRef = useRef<Slider>(null);
  const [initialSlide, setInitialSlide] = useState(0);

  const settings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    slidesToShow: 1,
    initialSlide: initialSlide,
    speed: 500,
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (index: number) => setInitialSlide(index),
    beforeChange: (_current: any, next: any) => setInitialSlide(next),
  };

  return (
    <>
      <div className="single-slide px-0 md:px-20 lg:px-40 xl:px-60">
        <Slider ref={sliderRef} {...settings}>
          <div>
            <img className="w-full" src="/images/rooms/moco_1.jpg" alt="" />
          </div>
          <div>
            <img className="w-full" src="/images/rooms/moco_2.jpg" alt="" />
          </div>
          <div>
            <img className="w-full" src="/images/rooms/moco_3.jpg" alt="" />
          </div>
        </Slider>
        <div className="grid px-10 pb-10 md:grid-cols-4">
          <div className="text-center text-3xl capitalize  text-green md:col-span-1 md:text-left">
            {t('menu_room_moco_suite')}
          </div>
          <div className="text-center md:col-span-3 md:pl-2 md:text-left">
            {t('room_desc_moco')}
          </div>
        </div>
      </div>
      <div className="bg-greenYellowLight py-5 px-10 md:py-10 md:px-20 lg:px-40 xl:px-60">
        <div className="grid md:grid-cols-3">
          <div className="md:col-span-2">
            <div className=" py-5 text-center text-3xl text-green md:pl-10 md:text-left">
              {t('complimentary')}
            </div>
            <Complimentary />
          </div>
          <div className="md:col-span-1 md:pl-10">
            <div className=" py-5 text-center text-3xl text-green md:text-left">
              {i18n.language !== 'th' && t('key_features')}
            </div>

            {i18n.language === 'en' ? (
              <>
                <ul className="text-base text-green">
                  <li>- Size 72 m2</li>
                  <li>- Garden view and panoramic city view</li>
                  <li>- Capacity 2 adults</li>
                  <li>- Handheld shower head and rain shower head</li>
                  <li>- Bathtub with crystal bath salt</li>
                  <li>- TV 50”</li>
                  <li>- Living room</li>
                  <li>- Work desk</li>
                </ul>
                <div className="pt-2 text-base font-bold">Full Amenities</div>
                <ul className="text-base text-green">
                  <li>- Air Conditioner</li>
                  <li>- In-room safe</li>
                  <li>- Refrigerator</li>
                  <li>- Hairdryer</li>
                  <li>- Telephone</li>
                  <li>- Nespresso coffee machine</li>
                  <li>- JBL Speaker with alarm clock</li>
                  <li>- Wi-fi</li>
                  <li>- Breakfast</li>
                  <li>- Welcoming drink</li>
                  <li>- Free minibar</li>
                </ul>
              </>
            ) : (
              <>
                <ul className="pb-4 text-xl text-green">
                  <li className="font-modny text-base">MOCO Suite</li>
                  <li>ขนาดห้องพัก: 72 ตร.ม.</li>
                  <li>วิว: วิวสวน และวิวเมืองแบบมุมกว้าง</li>
                  <li>เหมาะสำหรับ: ผู้ใหญ่ 2 ท่าน</li>
                  <li>ห้องอาบน้ำ: ห้องอาบน้ำฝักบัวมือและฝักบัวแบบสายฝน</li>
                  <li>ทีวี: ขนาด 50 นิ้ว</li>
                  <li>มุมนั่งเล่น</li>
                  <li>มุมทำงาน</li>
                </ul>

                <div className="pt-2 text-2xl font-bold">สิ่งอำนวยความสะดวกครบครัน</div>
                <ul className="text-xl text-green">
                  <li>เครื่องปรับอากาศแบบ</li>
                  <li>ตู้นิรภัย</li>
                  <li>ตู้เย็น</li>
                  <li>เครื่องเป่าผม</li>
                  <li>โทรศัพท์</li>
                  <li>เครื่องชงกาแฟ Nespresso</li>
                  <li>ลำโพง นาฬิกาปลุก JBL</li>
                  <li>อินเตอร์เน็ต – ไวไฟ</li>
                  <li>อาหารเช้า</li>
                  <li>เครื่องดื่มต้อนรับเมื่อมาถึง</li>
                  <li>มีบริการเครื่องดื่มและของว่างตลอดวัน</li>
                </ul>
              </>
            )}
            <div className="py-5 text-green">
              <div>{t('check_in_time')}: 15:00</div>
              <div>{t('check_out_time')}: 12:00</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MocoSuite;
