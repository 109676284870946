import { Dispatch, useReducer, createContext } from 'react';

export enum DINING {
  PAR = 'menu_dining_par',
  BAR = 'menu_dining_bar',
  BRANCH_AND_MUNCH = 'menu_dining_branch',
}

type InitialStateType = {
  diningType: DINING;
};

interface IContextProps {
  state: InitialStateType;
  dispatch: Dispatch<any>;
}

const initialState = {
  diningType: DINING.PAR,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'GET_DINING_TYPE':
      return state;
    case 'UPDATE_DINING_TYPE':
      return {
        ...state,
        diningType: action.payload.diningType,
      };
    default:
      return state;
  }
};

export const MocoContext = createContext<IContextProps>({
  state: initialState,
  dispatch: () => null,
});

export const MocoContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <MocoContext.Provider value={{ state, dispatch }}>{children}</MocoContext.Provider>;
};
