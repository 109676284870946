import { useEffect } from 'react';
import DesktopMenu from './desktop';
import MobileMenu from './mobile';
import VideoTitle from './video';

const Header = () => {
  const handleStickyMenu = () => {
    let mocoMenu: any = document.getElementById('mocoMenu');
    let space: any = document.getElementById('space');
    let header: any = document.getElementById('header')?.clientHeight;
    const sticky = mocoMenu ? mocoMenu.offsetTop : 0;
    if (mocoMenu && window.pageYOffset > sticky && window.pageYOffset > header) {
      mocoMenu.classList.add('sticky');
      space.classList.add('h-32');
    } else if (mocoMenu && space) {
      mocoMenu.classList.remove('sticky');
      space.classList.remove('h-32');
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      handleStickyMenu();
    };
  }, []);

  return (
    <>
      <div className="flex md:hidden">
        <MobileMenu />
      </div>
      <div id="header">
        <VideoTitle />
      </div>
      <div className="z-10 hidden md:flex" id="mocoMenu">
        <DesktopMenu />
      </div>
      <div id="home"></div>
      <div id="space" className="hidden md:flex"></div>
    </>
  );
};

export default Header;
