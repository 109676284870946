import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import Slider from 'react-slick';
import { DINING } from '../../../../context/MocoContext';
import i18n from '../../../../utils/i18n';
import { NextArrow, PrevArrow } from '../../../../utils/slider-arrow';
import { SOCIAL_URL } from '../../../../utils/social-url';
import TitleSection from '../../../TitleSection';
const Energise = () => {
  const { t } = useTranslation();
  const location = useLocation().search;
  const [initialSlide, setInitialGallery] = useState(DINING.PAR);

  const settings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    slidesToShow: 1,
    initialSlide: 0,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    const params = new URLSearchParams(location);
    const section = params.get('section');
    if (section) {
      const type =
        section === 'bar'
          ? DINING.BAR
          : section === 'branch'
          ? DINING.BRANCH_AND_MUNCH
          : DINING.PAR;
      setInitialGallery(type);
    }
  }, [location]);

  return (
    <div className="block py-4" id="par">
      <TitleSection title={t('menu_dining')} />
      <div className="flex md:hidden">
        <div className="py-6 sm:text-center md:pr-4">
          <div className="text-center text-xl font-bold text-green md:text-left md:text-3xl">
            {t('energise_title')}
          </div>
          <div className="text-center md:text-left">{t('energise_desc')}</div>
          <img className="px-12 py-4 sm:w-full md:px-10" src="/images/polaroid.png" alt="" />
        </div>
      </div>
      <div className="grid grid-cols-4 md:grid-cols-3">
        <button
          onClick={() => {
            setInitialGallery(DINING.PAR);
          }}
          className={`${
            i18n.language === 'th' ? 'text-2xl' : 'text-sm md:text-lg'
          } mt-4 w-full border border-greenYellowLight bg-gold py-2 text-center text-white md:border-2 md:px-9 ${
            initialSlide !== DINING.PAR ? 'disable' : ''
          }`}
        >
          {t('menu_dining_par')}
        </button>
        <button
          onClick={() => {
            setInitialGallery(DINING.BAR);
          }}
          className={`${
            i18n.language === 'th' ? 'text-2xl' : 'text-sm md:text-lg'
          } mt-4 w-full border border-greenYellowLight bg-gold py-2 text-center text-white md:border-2 md:px-9 ${
            initialSlide !== DINING.BAR ? 'disable' : ''
          }`}
        >
          {t('menu_dining_bar')}
        </button>
        <button
          onClick={() => {
            setInitialGallery(DINING.BRANCH_AND_MUNCH);
          }}
          className={`${
            i18n.language === 'th' ? 'text-2xl' : 'text-sm md:text-lg'
          } col-span-2 mt-4 w-full border border-greenYellowLight bg-gold py-2 text-center text-white md:col-span-1 md:border-2 md:px-9 ${
            initialSlide !== DINING.BRANCH_AND_MUNCH ? 'disable' : ''
          }`}
        >
          {t('menu_dining_branch')}
        </button>
      </div>
      <div className="single-slide flex grid-cols-1 flex-col-reverse md:grid md:grid-cols-3 ">
        <div className="hidden py-6 sm:text-center md:inline md:pr-4">
          <div className="text-center text-xl font-bold text-green md:text-left md:text-3xl">
            {t('energise_title')}
          </div>
          <div className="text-center md:text-left">{t('energise_desc')}</div>
          <img className="px-12 py-4 sm:w-full md:px-10" src="/images/polaroid.png" alt="" />
        </div>
        <div className="col-span-1 text-center md:col-span-2">
          {initialSlide === DINING.PAR && (
            <Slider {...settings}>
              <div>
                <img className="w-full" src="/images/dining/par.jpg" alt="" />
              </div>
            </Slider>
          )}

          {initialSlide === DINING.BAR && (
            <Slider {...settings}>
              <div>
                <div className="w-100 flex justify-center align-middle">
                  <img className="w-auto" src="/images/dining/bar.jpg" alt="" />
                </div>
              </div>
            </Slider>
          )}

          {initialSlide === DINING.BRANCH_AND_MUNCH && (
            <Slider {...settings}>
              <div>
                <img className="w-full" src="/images/dining/branch_munch_1.jpg" alt="" />
              </div>
              <div>
                <img className="w-full" src="/images/dining/branch_munch_2.jpg" alt="" />
              </div>
              <div>
                <img className="w-full" src="/images/dining/branch_munch_3.jpg" alt="" />
              </div>
            </Slider>
          )}
          <div className="px-6">
            {initialSlide === DINING.PAR && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-4">
                  <div className="text-center text-3xl text-green md:col-span-1 md:text-left">
                    {t('par_title')}
                  </div>
                  <div className="text-center md:col-span-3 md:pl-6 md:text-left">
                    <div className="pb-4">{t('par_desc')}</div>
                  </div>
                </div>
                <div className="mr-2 flex justify-end">
                  <img
                    className="mx-4 cursor-pointer"
                    src="/images/ic-facebook.svg"
                    alt=""
                    onClick={() => window.open(SOCIAL_URL.PAR.facebook)}
                  />
                  <img
                    className="cursor-pointer"
                    src="/images/ic-instagram.svg"
                    alt=""
                    onClick={() => window.open(SOCIAL_URL.PAR.instagram)}
                  />
                </div>
              </>
            )}
            {initialSlide === DINING.BAR && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-4">
                  <div className="text-center text-3xl text-green md:col-span-1 md:text-left">
                    {t('bar_title')}
                  </div>
                  <div className="text-center md:col-span-3 md:pl-6 md:text-left">
                    <div className="pb-4">{t('bar_desc')}</div>
                  </div>
                </div>
                {/* <div className="flex justify-end mr-2">
                  <img className="mx-4 cursor-pointer" src="/images/ic-facebook.svg" alt="" onClick={() => window.open(SOCIAL_URL.BAR.facebook)} />
                  <img className="cursor-pointer" src="/images/ic-instagram.svg" alt="" onClick={() => window.open(SOCIAL_URL.BAR.instagram)} />
                </div> */}
              </>
            )}
            {initialSlide === DINING.BRANCH_AND_MUNCH && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-4">
                  <div className="text-center text-3xl text-green md:col-span-1 md:text-left">
                    {t('branch_title')}
                  </div>
                  <div className="text-center md:col-span-3 md:pl-6 md:text-left">
                    <div className="pb-4">{t('branch_desc')}</div>
                  </div>
                </div>
                <div className="mr-2 flex justify-end">
                  <img
                    className="mx-4 cursor-pointer"
                    src="/images/ic-facebook.svg"
                    alt=""
                    onClick={() => window.open(SOCIAL_URL.BRANCH_MUNCH.facebook)}
                  />
                  <img
                    className="cursor-pointer"
                    src="/images/ic-instagram.svg"
                    alt=""
                    onClick={() => window.open(SOCIAL_URL.BRANCH_MUNCH.instagram)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Energise;
